const LIST_COUNTRY = [
  {
    code: "au",
    countryName: "AU"
  },
  {
    code: "nz",
    countryName: "NZ"
  }
];
export { LIST_COUNTRY as L };
